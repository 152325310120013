@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900|Montserrat:100,200,300,400,500,600,700,800,900|Open+Sans:300,400,600,700,800|Roboto:100,300,400,500,700,900");
a:hover,
a:focus {
  text-decoration: none !important; }

body {
  background: #ffffff; }

.bg-curso {
  height: 350px !important;
  /* background-image: url("../images/redes.png"); */
  background-repeat: no-repeat;
  /* background-attachment: fixed;  */
}

.title-curso{
  font-size: 18px;
  text-align: center;
  position: relative;
  top: 0px;
}
  .accordion {
    border: none!important;
    border-radius: 26px;
  }
  
  .accordion__button {
    background-color: #085fa3!important;
    color: #fff!important;
    cursor: pointer;
    padding: 6px 19px!important;
    max-width: max-content;
    text-align: center!important;
    border: none!important;
    border-radius: 16px;
    margin-bottom: 16px;
  }
  .accordion__button:hover {
    background-color:none!important;
  }
 
  .accordion__button:before {
    display: inline-block;
    content: none!important;
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}


.accordion__panel {
  padding: 0px!important; 
  -webkit-animation: fadein 0.35s ease-in;
  animation: fadein 0.35s ease-in;
}

.bloco-desconto{
    width: 117px;
    height: 50px;
    background-color: #063e6b;
    float: right;
    display: block;
    position: absolute;
    top: 150px;
    z-index: 50;
    transform: rotate(-20deg);
    left: 720px;
    border: 5px solid #0069D9;
    color: #fff;
    text-align: center;
    font-size: 21px;
    font-weight: 900;
    padding: 4px;
}

#pelicula {
  width: 100%;
  height: 170px;
  display: block;
  position: absolute;
  top: 279px;
  content: '';
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); }

.name-course {
  font-family: "Lato", sans-serif;
  color: #ffffff;
  font-size: 55px;
  font-weight: 700;
  padding-top: 160px;
  position: absolute;
  left: 0px;
  text-shadow: 0px 0px 80px #000000;
  line-height: 58px;
  max-width: 100%; }

.container-fluid-courses {
  width: 85%; 
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;}
  h1 {
    margin-bottom: 0.7%;
  }

.about_course {
  padding: 10px 0 0 0; }
  .about_course > .container-fluid-courses > .row > .col-sm-9 > h1 {
    padding: 10px 0 0 0;
    max-width: 100%;
    text-align: justify;  
    font-size: 28px;
    font-weight: 800;
    color: #3a3a3a;
    letter-spacing: -0.3px;
    font-family: "Lato", sans-serif;
  }
  .about_course > .container-fluid-courses > .row > .col-sm-9 > p {
    padding-top: 10px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: #333; }

.no-padding {
  padding: 0; }
  .no-padding > .col-sm-12 {
    padding-right: 50px; }

.card-off {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 1px solid #dcdcdc; }

.video {
  max-height: 210px;
  margin-bottom: 5px;
  margin-top: 15px; }

.video  > *{
  border: none; 
  width:100%;
   height:210px;
}

.global{
  margin-top: 18px;
  margin-left: -16px;
}
/* .informacoes {
  padding: 0px;
} */

p.descricao-text{
  padding-top: 5px!important;
  margin: 8px 0!important;
  position: relative;
  top: -15px;
}

  .cards-modalidades {
  background-color: #f5f5f5;
  border: 1px solid #eaeaea;
  border-radius: 8px; }

.header-card {
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }
  .header-card > h2 {
    text-transform: uppercase;
    text-align: center;
    font-size: 23px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px; }

.header-card-ead {
  background-color: #3498db; }

.header-card-presencial {
  background-color: #e67e22; }

.header-card-semi-presencial {
  background-color: #f1c40f; }

select, .width-full {
  width: 100%; }

.turno, .valor, .inscrevase {
  font-weight: 600;
  font-family: 'Lato', sans-serif; }

.value--new {
  font-weight: 600;
  color: #828282;
  font-size: 19px;
  margin-top: 8px;}

  /* a:not([href]):not([tabindex]) {
     color: #82828d;
  }  */

.value--old {
  font-weight: 600;
  color: #828282;
  font-size: 14px; 
  text-decoration: line-through;
}
.value--old-usar-enquanto-nao-novo-campo{
  font-weight: 600;
  color: #eb5463;
}

.dia-util, .promocional{
  font-size: 13px;
  font-weight: 600;
  top: -2px;
  position: relative;
}
.promocional{
  color: #177fde;
}
.dia-util{
  color: #828282;
}

.value--desc{
  font-weight: 800;
  color: #177fde;
  font-size: 25px;
  margin-top: 8px;
}

.value--new, .value--old,.value--old-usar-enquanto-nao-novo-campo {
  font-family: "Lato", sans-serif; }

.bt-inscrevase {
  border-radius: 3px; }

.btn-enem{
  background: #0075db;
  border: 1px solid #0272d4;
}

.btn-enem:focus,
.btn-enem:hover{
  background: #218cff !important;
  border: 1px solid #218cff !important;
}

.clearfix::before, .clearfix::after {
  content: ' ';
  display: table; }

.clearfix::after {
  clear: both; }

.lista-unidades {
  height: 292px;
  overflow: auto; 
  padding-right: 10px;
  background-color: whitesmoke;
}

.text-unidades {
  margin-bottom: 5px !important; }

.pesquisar {
  margin-bottom: 10px;
  width: 100%;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem 1.75rem .375rem .75rem;
}

.card-body, .card-body-cartao {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;}

.card-body-cartao{
  padding: 0;
}

.pd-graduacao {
  padding: 35px 0 40px 0; }

.description-course {
   padding-left: 0px;
   padding-right: 0px;
   padding-top: 20px;
   padding-bottom: 0px;
  }
  .description-course > ul {
    list-style-type: none;
    padding-left: 0px;
    color: #333;
    font-family: "Lato", sans-serif;
    margin-bottom: 5px; }
  .description-course > ul > li {
    font-size: 15px;
    padding-bottom: 10px;
    padding-right: 30px; }
    .description-course > ul > li > strong {
      font-size: 15px; }
  .description-course > ul > li > svg {
    font-size: 21px;
    padding-right: 3px;
    margin-bottom: -3px; }
#info-id{
  font-size:30px !important;
  padding-left: 5px;
}
.uppercase{
  text-transform: uppercase;
}

.matriz-curricular{
  background: #085fa3;
  background-color:#085fa3;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  display: block;
  margin: 9px auto;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.matriz-curricular > i {
  padding-right: 5px;  
 }

.fazer-prova {
  padding: 20px 20px;
  background-color: #09B5A5;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0px 5px 0px 0px #0d9c8f;
  -webkit-box-shadow: 0px 5px 0px 0px #0d9c8f;
  outline: none;
  border: none;
  margin-top: 20px;
  margin-left: 140px;
}

.fazer-prova:hover {
  color: #fff;
}

.finalizar-pagamento, .imprimir-comprovante,.salvar-pdf{
  background: #085fa3;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  /* margin: 0 auto 15px auto;
  display: block; */
  background-color: #085fa3;
  -webkit-box-shadow: 0px 5px 0px 0px #024d88;
  box-shadow: 0px 5px 0px 0px #024d88;
  width: 205px; }

  .pre-matricula{
    background: #085fa3;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 13px;
    font-weight: 600;
    /* margin: 0 auto 15px auto;
    display: block; */
    background-color: #085fa3;
    ;
   }

.imprimir-salvar-comprovante{
  text-align: center;
  margin: 30px 0px 60px 0px
} 
.imprimir-salvar-comprovante > button:nth-child(1){
  margin-right: 15px;
}
.imprimir-salvar-comprovante > button:nth-child(3), button:nth-child(4){
  margin-left: 15px;
}
.matriz-curricular:hover,
.matriz-curricular:focus,
.finalizar-pagamento:hover,
.finalizar-pagamento:focus,
.imprimir-comprovante:hover,
.imprimir-comprovante:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #085fa3; }
  
  .salvar-pdf:hover,
  .salvar-pdf:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #05a898; }
    
    .title_course{
      margin: 0 160px;
    }
    
    /***Media***/
    @media(min-width: 1995px){
      .bg-curso{
        background-size: cover;
      }
    }
    
    @media (max-width: 1440px){
      section.bg-curso{
        background-position: 0px -7px;
        background-size: 105%;
      }    
    }
    @media(min-width: 1355px) and (max-width: 1452px) {
      .value--new {
        font-size: 25px !important;
      }
      .header-card > h2{
        font-size: 20px !important;
      }
    }
      
      @media (max-width: 1355px) {
        #col-mod-total{
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .cards-modalidades{
          margin-top: 25px !important;    
        }
        .description-course {
          padding: 20px 15px 20px 15px; }
          
          .header-card > h2 {
          font-size: 18px; }
          
          .col-mod-12 {
            flex: 0 0 100%;
            max-width: 100%;
            -ms-flex: 0 0 100%; }
            
        .col-mod-4 {
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%; }
              
        .lista-unidades {
            height: 153px; } }
                
      @media (max-width: 1315px) {
        .name-course {
          font-size: 45px;
          /* left: 40px; */
          max-width: 100%; }

          .title_course {
            margin: -97px 57px;
          }
        }

      @media only screen and (max-width: 1200px) {
        .container-fluid-courses{
          width: 85%; } }

      @media(min-width: 1075px){
        #cartao-credito, #resumo-compra{
          margin-top: 25px;
          padding-bottom: 55px;
        }
        #resumo-compra{
          padding-right: 0px;
        }
      }
      @media(max-width: 1075px){
        #cartao-credito, #resumo-compra{
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
        #resumo-compra{
          margin-top: 35px;
          margin-bottom: 55px;
          padding-right: 0px;
          padding-left: 0px;
        }
        #cartao-credito{
          margin-top: 35px;
        }
      }                
        @media (max-width: 640px){
          section.bg-curso{
            background-position: -57px 0px;
            background-size: 115%;
          }    
        }

  
  @media (max-width: 320px){
  section.bg-curso {
      background-position: -255px -3px;
      background-size: 190%;
  }    
  .title_course {
    margin: 0 160px;
}
  }
@media(max-width:818px){
  .value--new {
    font-size: 28px;
  }
}


#cartao-credito > article > div > article{
  padding: 2rem;
}  

@media (max-width: 900px) {
  .col-mod-12-cards {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 25px; }

  .pd-graduacao {
    padding: 0px 0 20px 0; }

  .btn-segunda-graduacao {
    margin-bottom: 25px; } }


@media (min-width: 768px) and (max-width: 991px) {
  #pelicula {
    top: 279px; } }


@media(max-width: 559px) and (min-width: 320px){
  .bg-curso {
    height: 206px !important;
  }
  #pelicula{
    top: 136px;}

  section.bg-curso {
    background-position: -255px 197px;
    background-size: 190%;
} 
.title_course {
  margin: -97px 57px;
}
}
@media (max-width: 767px) and (min-width: 560px) {
  .bg-curso {
    height: 235px !important; }
  #pelicula{
     top: 136px;
  }
  .name-course {
    /* left: 20px; */
    padding-top: 125px; 
    font-size: 32px !important;} }
@media (max-width: 717px) {
  .name-course {
    font-size: 40px;
    line-height: 45px; } }

@media (max-width: 539px) {
  .name-course {
    font-size: 35px; }
  
  .title_course {
      margin: -95px 76px;
    }
  .title_course-1{
    position: relative;
    top: -83px;
  }
  }

@media (max-width: 472px) {
  .name-course {
    font-size: 30px;
    line-height: 35px; } }

@media (max-width: 404px) {
  .name-course {
    font-size: 32px;
    line-height: 30px; }
  .btn-segunda-graduacao, .transf-externa {
    padding: 6px 15px;} 
  .container-fluid-courses {
    width: 95%;}}

@media(max-width: 674px){
  .btn-segunda-graduacao, .transf-externa{
    margin-left: 0px;
  }
}
.pd-bt{
  padding-bottom: 50px;
}

.lista-unidades::-webkit-scrollbar {
    width: 5px;
}

.lista-unidades::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

.lista-unidades::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
}

.lista-unidades::-webkit-scrollbar-thumb:hover {
    background: #ffffff; 
}
.border-none{
  border: none !important;}
.form-cartao > input {
  border-radius: 5px;
}
.mg-bt-15{margin-bottom: 15px;}
.mg-bt-25{margin-bottom: 25px;}
article .card{
  background: #fbfbfb;
}
.card{
  border-radius: 5px !important;
}
.text-inscricao {
    margin-top: 0px !important;
}
@media (max-width: 470px){
  .rccs__card, .rccs{
    width: 100% !important;
  }
  .center > button{
    width: 100%;
  }
}
.mgbt-inscr{
  margin-top: 20px;
  margin-bottom: 20px;
}
.resumo-compra-left{
  font-size: 14px;
  font-weight: 700;
  color: #464646;
}
#pd-resumo{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
#pd-resumo .row{
  padding-bottom: 4px;
  padding-top: 8px;
  border-bottom: 1px solid #f1f1f1;
  margin-right: 0;
  margin-left: 0;
}
.border-none{
  border: none !important;
}
.card-dados-candite{
  border: 1px solid #e1e1e1;
  background: #f9f9f9;
  padding: 15px 30px;
  margin: 0 auto 20px auto;
  display: block;
}
/* .imprimir-comprovante{
  margin: 15px auto 55px auto !important;
  display: block;
} */
.salvar-pdf{
  /* margin: 15px auto 55px auto !important;
  display: block; */
  background-color:#09B5A5;
  -webkit-box-shadow: 0px 5px 0px 0px #059687;
  box-shadow: 0px 5px 0px 0px #059687;
}
embed #plugin > *{
  font-size:80px !important;
}
.logo-center{
  margin: 20px auto;
  display: block;
}
.end{
  margin: 0 auto;
  display: block;
  text-align: center;
  padding-bottom: 20px;
}
.font-700{font-weight: 700;}
.input-height{height: 40px !important;border-radius: 5px;}
.mgtop{margin-top: 30px;}
.obrigado-inscricao{
  text-align: center;
  font-size: 30px;
  color: #024d88;
  font-weight: 900;
  padding-bottom: 10px;
}
.text-pos-obrigado{
  text-align: center;
  font-size: 16px;
  padding-bottom: 30px;
}
.forma-ingresso{
  font-size: 25px;
  font-weight: 700;
  color: #3a3a3a;
  letter-spacing: -0.3px;
  font-family: "Lato", sans-serif;
}
.no-pd{padding: 0;}
.mgbt-20{margin:30px 0 40px 0;}
.radio-ingresso{
  float: right;
  top: 26px;
  position: relative;
  right: 10px;
}
.label-ingresso{
  width: 100%;
  background: #ffffff;
  border: 1px solid #085fa3;
  border-radius: 20px;
  color: #085fa3;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 17px;
  padding-right: 33px;
  cursor:pointer;
  text-align: left;
}
.label-ingresso:hover{
  background: #085fa3;
  color: #ffffff;  
  transition: 0.2s ease-in;
}
.radio {
  flex: 10 0 10% !important;
  max-width: 100% !important;
}
.radio > input:checked + label{
  background: #085fa3;
  color: #ffffff;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
input[type=radio]{
  -webkit-appearance: none;
  border: 1px solid #085fa3;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: relative;
  top: 30px;
  right: 10px;
}
input[type=radio]:checked{
  -webkit-appearance: none;
  background-color: #085fa3;
  border: 5px solid #fff;
  box-shadow: 0 0 0 1px #085fa3;
  outline: none;
}


@media (max-width: 890px){
#radio-full > .row > .col-sm-2 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

#dados{
  padding:10px 0px 30px 0px;
}
#confirmar-cancelar{text-align: center;}
.bt-confirmar, .bt-cancelar{
  padding: 15px 30px;
  border-radius: 8px;
  margin: 30px 30px 30px 30px;
  
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  box-shadow: none !important;
}
.bt-confirmar{
  background-color: #009688;
  border-color: #009688;
}
.bt-cancelar{
  background-color: #828282;
  border-color: #828282;
}
.bt-cancelar:hover,
.bt-cancelar:active,
.bt-cancelar:focus{
  background: #6b6b6b !important;
  border-color:#6b6b6b !important;
}
.bt-cancelar{margin-right: 60px;}
.bt-confirmar:hover,
.bt-confirmar:active,
.bt-confirmar:focus{
  background-color: #09b5a5 !important;
  border-color: #09b5a5 !important;

}
.text-disciplinas-cursadas{
  display: inline-block;
  padding-left: 10px;
}



/***Alteracao 24-08-2018*****/
.wpp{
	font-size:20px !important;
	font-weight:500;
}
.wpp:hover, .wpp:focus{
	color: #ffffff !important;
}
.icon-wpp{
	width:20px;
	position: relative;
	bottom: 5px;
	margin-left:10px;
}
.container-fluid {
  padding-right: 30px;
  padding-left: 30px;
}

.btn-inscrevase {
  /* background: linear-gradient(to right, rgba(230,27,114,1) 0%,rgba(29,112,183,1) 100%); */
  background: #014e9e ;
  color: #ffffff;
border: 1px solid #fff;
padding: 9px 21px;
right: 20px;
position: absolute;
letter-spacing: 0.5px;
font-weight: 700;
font-size: 15px;
width: 129px;
height: 37px;
}
    


.btn-inscrevase:hover {
  /* background: linear-gradient(to right, rgba(29,112,183,1) 0%,rgba(230,27,114,1) 100%); */
  background: rgba(1,78,158,0.8);
  color: #ffffff;
border: 1px solid #fff;
padding: 9px 21px;
right: 20px;
position: absolute;
letter-spacing: 0.5px;
font-weight: 700;
font-size: 15px;
width: 129px;
height: 37px;
}




@media (max-width: 767px){
  .btn-inscrevase {
  }
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.callback .form-control {
  background: #ffffff;
  border-color: #ffffff;
}
.btn-lg{
  cursor: pointer;
  width: 100%;
}
/* header{
  position: absolute;
  width: 100%;
  z-index: 9;
} */
.container-fluid{
  margin-top: 15px;
}
@media(max-width: 1880px){
  .main-landing {
    background-position-y: 0px;
  }
}
.sm-offset {
  margin-left: 58.333333%;
}
@media (max-width: 991px){
  .sm-offset {
      margin-left: 0%;
  }
}

@media (max-width: 1366px){
  section.bg-curso{
    background-position: -4px -1px;
    background-size: 136%;
}}
  
@media (max-width: 838px) and (min-width: 768px){
  .wpp{
    font-size: 17px !important;
  }
  #pelicula {
    top: 279px;
  }
}
@media (max-width: 767px){
  .container-fluid {
    margin-top: 0px;
  }
}
 .site-footer{
    width: 100%;
    bottom: 0;
    padding: 15px 15px !important;
    position: relative !important;
    /* margin-top:135px */
  }

 .form-buscar-candidato{
  margin-top: 40px;
 }
 #btn-buscar-candidato{
  margin: -10px auto 70px auto;
  display: block;
  width: 32%;
  padding: 13px 0px;
  border-radius: 8px;
 }
 .link-continuar-inscricao-candidato{
      margin-bottom: 50px;
    }
 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
button:hover,
button:focus,
.lista-unidades > li:hover,
.lista-unidades > li:focus{
  cursor: pointer;
  outline: none;
}
.mgtop-progress{
  margin: 45px 0px;
}
.mgb-40{
  margin-bottom: 40px;
}
.modal-preview > p{
  font-size: 17px;
  font-weight: 400 !important;
  color: #505050 !important;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 27px;
}

/* .nav-preview > li:not(:first-child){
  padding-left: 15px !important;
} */
.text-p{
  position: relative;
  top: -20px;
}
.nav-preview{
  border: 0;
  text-align: center;
  margin: 15px auto 15px auto;
  display: block;
}
.nav-preview > li{
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #085fa3;
  border: 0px;
  border-radius: 3px;
}
.nav-preview > li > a{
  font-weight: 600;  
}
.nav-preview > li:hover,
.nav-preview > li:focus{
  cursor: pointer;
}
.nav-preview > li.active{
  color: #fff;
  background: #085fa3;
}
.tab-preview{
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.list-preview{
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
  text-align: center;
  margin-top: 20px;
}
.list-preview > li{
  display: inline-block;
}
.list-preview > li:not(:first-child){
  padding-left: 30px;
}
.list-preview > li > button{
  padding: 6px 25px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 3px;
  outline: none;
  border: none;
}
.list-preview > li:nth-child(1) > button:hover,
.list-preview > li:nth-child(1) > button:focus{
  background: #e8e8e8;
}
.list-preview > li:nth-child(2) > button:hover,
.list-preview > li:nth-child(2) > button:focus{
  background: #0463c7;
}
.list-preview > li:nth-child(2) > button{
  background: #085fa3;
  color: #fff;
  border: 1px solid #085fa3;
}
.section-bg-disciplinas{
  background: #eaf0f6;
  padding: 30px 30px;
  border-radius: 3px;
  border: 1px solid #d6dce2;
  margin-bottom: 50px;
}
.h6-disciplinas-cursadas{
  color: #04274c  ;
  margin-top:35px;
  margin-bottom: 15px;
  font-weight: 600;
}
.checkbox *:hover,
.checkbox *:focus{
 cursor: pointer !important;
}

.curso-aprovado,.preview-curso-aprovado{
  display: table-header-group;
  color: #333;
  font-weight: 600;
}
.preview-curso-aprovado{
  font-size:23px;
}

.universidade-aprovado,.preview-universidade-aprovado{
  font-size: 16px;
  color: #676767;
  text-align: left;
}

.preview-universidade-aprovado{
  font-size: 20px;
}

button .curso-aprovado .universidade-aprovado:hover,
button .curso-aprovado .universidade-aprovado:focus{
  text-decoration: none;
}
.btn-curso-uni-aprovado{
  background: none; 
  border: none;
  width: 100%;
}
.section-select-disciplinas{
  height: 320px;
  overflow-y: scroll;
}
.editar-disciplina{
  margin-left: 15px;
}
.deletar-disciplina{
  margin-left: 10px;
}
.deletar-disciplina{
  color: #e43849;
}
.deletar-disciplina:hover,
.deletar-disciplina:focus{
  color: #fb5665;
}
.section-btn-add-disciplina {
  text-align: center;
}
.section-btn-add-disciplina > .btn-add-disciplina{
  margin-top: 15px;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 5px;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.section-btn-add-disciplina > .btn-add-disciplina:nth-child(1){
  background: #337ab7;
}
.section-btn-add-disciplina > .btn-add-disciplina:nth-child(2){
  margin-left: 20px;
  background: #0091ae;
}
.text-tittle-disciplinas{
  color: #006157;
}

.btn-handle-curso-externo{
  margin: 10px 5px 8px 2px;
}

.input-radio-disciplinas-cursadas{
  margin-right: 3px;
}
.accordion-card-disciplinas{
  cursor: pointer;
}
.disciplinas-salvas{
  color: #62E3BC;
}
.disciplinas-nao-salvas{
  color: #08427B;
}
.div-curso-universidade{
  margin-bottom: 15px;
}
.btn-editar-modal{
background: #eee;
color: #333;
border: 1px solid #e0e0e0;
}
#dateToPrint{
  margin-bottom: 20px;
}
.text-intiuicoes-oculta{
  margin-left: 10px;
  color: red;
  font-size: 14px;
  font-weight: 550;
}

.data-valor-promocional{
  color:#F32525;
  font-weight: 600;
  font-size: 13px;
}
.div-lista-disciplinas{
  border: solid 1px #08427e21;  
  border-radius: 25px;
  padding: 20px;
  width: 100%;  
  margin-bottom: 2px;
  margin-top: 2px;  
}

.btn-orange{
  background-color: #ff7122;
  color: #ffffff;
  font-weight: 500;
  border: none;
  padding: 5px 5px;
  border-radius: 5px;
  font-size: inherit;  
  margin-top: 15px;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  width: 100%;
}
.btn-orange:hover,
.btn-orange:focus{
  text-decoration: none !important;
  background-color: #ff6f22c0; 
} 
.small-text, .text-informativo{  
  font-size:11px;  
  text-align:left;
}
.small-text{
  color: #08427e;
}
.text-informativo{
  margin:15px;
  color:#ed691f;
} 

/* ANIMAÇÃO HAND FORM ARQUIVOS PENDENTES */

@keyframes anima_handpoint {
  from{
      transform: translateX(-40%) 
      translateZ(0);
  }
  to{
      transform: translateX(0%)
       translateZ(0);

  }                
}
.icon-hand-point-right{
  animation: anima_handpoint .7s ease-out infinite alternate-reverse;  
  color: #ed691f;  
}  
.cep-consultation__button {
  background: 0;
  border: 0;
  text-decoration: underline;  
  color: #1e56c9;
}  

.wpp-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 114px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}
.wpp-button:hover {
  color: #fff;
}

.my-float{
margin-top:16px;
}

/* Mobile */
@media (max-width: 1024px){
  .wpp-button{
    font-size: 20px;
    bottom: 90px;
    right: 27px;
    width: 40px;
    height: 40px;
  }
  .my-float{
    margin-top: 11px;
  }
}


.btn-desistir-envio-documento {
  background: #ff7122;
  color: #ffffff;
  padding: 12px 5px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  /* margin: 0 auto 15px auto;
  display: block; */
  background-color: #ff7122;
  /* -webkit-box-shadow: 0px 5px 0px 0px #ff7122;
  box-shadow: 0px 5px 0px 0px #ff7122; */
  width: 205px; 
}

@media (max-width: 480px){
  section.bg-curso {
    background-position: -284px -10px;
    background-size: 192%;
}}
  
  @media (max-width: 360px){
  section.bg-curso {
      background-position: -284px -7px;
      background-size: 180%;
  }    
  }
