@import url("https://fonts.googleapis.com/css?family=VT323");
:root{
  --width: 280px;
  --height: 180px;
}
.error-500{
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'VT323';
  color: #1E4B6D;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, .3);
}

.error-500:after{
  content: attr(data-text);
  display: block;
  margin-top: calc(var(--height) / 10 + 15px);
  font-size: 28pt;
  text-align: center;
  max-height:15px;
}

.img-chapeu-universitario{
  margin-top:4%;  
  margin-bottom: 8%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.offset-5 {
  margin-left: 45.666667%;
}
