/**
 * Base
 */

/* :root {
  - -blue: #08427e;
  - -dark-blue: #0a263c;
  - -light-blue: #91b7dd;
  - -orange: #ef6a08;
} */

body {
  font-family: "Lato", sans-serif;
  background: url('./../../src/images/landing-bg.png') center top/cover no-repeat;
  background-size: 100%;
  }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 0;
  font-weight: bold;
}

/**
 * Bootstrap overrides
 */

 p.descricao{
  white-space: pre-wrap!important;
 }

.form-control, .btn {
  border-radius: 0;
}

.btn-primary {
  color: #fff;
  background: #085fa3;
  border-color: #085fa3;
  border-radius: .25rem;
}

.btn-enem-vestibular{
    /* border-radius: 8px; */
    /* background: linear-gradient(to right, rgba(230,27,114,1) 0%,rgba(29,112,183,1) 100%); */
    background: #014e9e;
border: 1px solid #fff;
letter-spacing: 0.5px;
font-weight: 500;
font-size: 15px;
color: #ffffff;
padding: 6px 0px;
}


.btn-enem-vestibular:hover, .btn-enem-vestibular:active {
  /* background: linear-gradient(to right, rgba(29,112,183,1) 0%,rgba(230,27,114,1) 100%);
  background-image: linear-gradient(to right, rgba(29,112,183,1) 0%,rgba(230,27,114,1) 100%); */
  background: rgba(1,78,158,0.8);
border: 1px solid #fff;
letter-spacing: 0.5px;
font-weight: 500;
font-size: 15px;
color: #ffffff;
padding: 6px 0px;
}

.modal-backdrop {
  background-color: #0a263c;
}

.card, .card-img-top {
  border-radius: 0;
}

.card-text {
  font-size: .9rem;
}

/**
 * Header
 */

.site-header {
  padding: 1rem 0;  
  text-align: center;
}

@media ( min-width: 768px) {
  .site-header {
    text-align: left;
  }
}

@media ( max-width: 767px) {
  .site-header-logo {
    max-width: 150px;
  }
}
.background-fatenp{
  height: 100%;
}
.background-fatenp, #footer-fatenp{
  background: #007e8d; /*#F8F6E9;*/
}

.background-unigranrio{
  background: rgb(49,100,172);
}

.site-header-nav1 {
  color: #91b7dd;
}

.site-header-nav1 a {
  font-size: 12px;
  color: #91b7dd;
  text-transform: uppercase;
}

.site-header-nav2 {
  color: #fff;
}

.site-header-nav2 a {
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
}

@media ( min-width: 768px) {
  .site-header-nav2 a {
    font-size: 15px;
  }
}
/* 
@media ( max-width: 767px) {
  .site-header-social {
    display: none;
  }
} */

.site-header-social a {
  display: inline-block;
  padding: 4px;
  color: #fff;
}
.site-footer-links a{
  padding: 4px;
  color: #FFF;  
  font-size: 0.7rem;
  
}
.site-footer-links a:hover{
  filter: brightness(90%);
  text-decoration: underline!important;
}

/**
 * Footer
 */

.site-footer {
  padding: 1.5rem 0 !important;
  color: #fff;
  background: #08427f;
}

/**
 * Main
 */

.main {
  padding-bottom: 3rem;
}

/**
 * Landing
 */

.landing-hero {
  position: relative;
}

.landing-hero-text {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: .75rem;
  line-height: 1.25;
}

@media ( min-width: 992px) {
  .landing-hero {
    display: none;
  }
}
.main-landing {
  padding: 2rem 0;
}
@media(min-height: 900px){
  /* .main-landing {
    width: 100vw;
    height: 100vh;
  } */
}
@media(max-width:991px){
  .main-landing {
    width: 100%;
    height: 100%;
  }
}
@media ( min-width: 992px) {
  body{
    overflow-x: hidden;
  }
}

.signup-btn {
  position: relative;
  display: block;
  margin-bottom: 1rem;
  font-weight: 800;
}

.signup-btn:hover {
  text-decoration: none;
}

.signup-btn-cta {
  padding: 1rem;
  font-size: 1rem;
  line-height: 1;
  color: #08427e;
  background: #fff;
}

@media ( min-width: 1200px) {
  .signup-btn-cta {
    padding: 1rem 1.5rem;
  }
}

.signup-btn-icon {
  max-width: 35px;
}

.signup-btn-cta b {
  display: block;
  font-size: 1.25rem;
  color: #0a263c;
  text-transform: uppercase;
}

@media ( min-width: 992px) {
  .signup-btn-cta {
    font-size: 1rem;
  }
  .signup-btn-cta b {
    font-size: 1.5rem;
  }
}

.signup-btn small {
  display: block;
  padding: .25rem 1rem;
  font-size: .9rem;
  color: #fff;
}

.signup-btn-btn {
  width: 100%;
  height: 40px;
  margin-right: -39px;
  font-size: 21px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background: #96c400;
}

@media ( min-width: 992px) {
  .signup-btn-btn {
    width: 323px;
    float: right;
    background: url(../images/hotsite-img/foo-bg.png) center center no-repeat;
  }
}

@media ( max-width: 767px) {
  .signup-btn {
    margin-bottom: .5rem;
  }
  .signup-btn small, .signup-btn-btn {
    display: none;
  }
}

/* Callback */

.callback {
  padding: 1rem;
  background: rgba(255, 255, 255, .8);
  /* margin-top: 20px; */
}

@media ( min-width: 1200px) {
  .callback {
    padding: 0;
    background: transparent;
  }
}

.callback label {
  color: #08427e;
}

.callback .form-control {
  background: transparent;
  border-color: transparent;
  border-bottom: 1px dotted #91b7dd;
}

.callback select.form-control {
  background-image: url(../images/hotsite-img/select.png);
  background-position: right center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.callback .btn {
  padding-right: 3rem;
  padding-left: 3rem;
  font-weight: 800;
  text-transform: uppercase;
  background-color: #97c32e;
  border-color: #97c32e;
}

/* Info */

.landing-info {
  display: none;
  margin-top: 2rem;
  line-height: 1.25;
}

@media ( min-width: 992px) {
  .landing-info {
    display: block;
  }
}

@media ( min-width: 1200px) {
  .landing-info {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
}

/**
 * Hero units
 */

.hero {
  padding: 50px 0;
  /* font-style: italic; */
  text-transform: uppercase;
  /*background: url(../images/hero-bg.jpg) center center/cover no-repeat;*/
}

.hero-course {
  height: 210px;
  /*background: url(../images/hero-course.jpg) center center/cover no-repeat;*/
}

.hero h1, .hero h2 {
  margin: 0;
}

.hero h1 {
  font-weight: 800;
  color: #0a263c;
  font-size: 35px;
  font-family: "Lato", sans-serif;
}

.hero h2 {
  color: #08427e;
}

/**
 * Filters
 */

.filters {
  padding: 20px 0;
  margin-bottom: 3rem;
  color: #fff;
  background: #08427e;
}

/**
 * Courses table
 */

.courses-table {
  font-size: .8rem;
}

@media ( min-width: 768px) {
  .courses-table {
    font-size: 1rem;
  }
}

.courses-table thead th {
  border-color: transparent;
}

.courses-table td {
  vertical-align: middle;
  border-top: 1px solid #dad9d9;
}

@media ( max-width: 767px) {
  .courses-table th:nth-of-type(2), .courses-table th:nth-of-type(3), .courses-table th:nth-of-type(4), .courses-table td:nth-of-type(2), .courses-table td:nth-of-type(3), .courses-table td:nth-of-type(4) {
    display: none;
  }
  .calendar, .c-event-grid, .c-grid {
    width: 100%;
  }
}

.courses-table .fa {
  margin-right: .5rem;
}

@media ( max-width: 767px) {
  .courses-table .btn-primary {
    font-size: .8rem;
  }
  .courses-table .fa {
    display: none;
  }
}

/**
 * Course info
 */

.course-info {
  padding: 1rem 0;
  margin-bottom: 3rem;
  color: #fff;
  background: #08427e;
}

.course-info .media {
  margin-bottom: 1rem;
  font-size: .75rem;
  font-weight: bold;
}

@media ( min-width: 992px) {
  .course-info .media {
    margin-bottom: 0;
  }
}

.course-info .fa {
  font-size: 1.5rem;
}

.units {
  padding: 1rem;
  /* background: #c6c6c6; */
  background: whitesmoke;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  text-align: left;
}

.units-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.units-item {
  padding-bottom: 2px;
  padding-top: 2px;
  text-align: left;
  float: initial;
}

.units-link {
  display: block;
  font-weight: bold;
  color: #fff;
  font-size: .8rem;
  border-radius: .25rem;
  margin-left: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  text-align: left;
}

@media ( min-width: 1200px) {
  .units-link {
    font-size: 1rem;
  }
}

.units-link:hover, .units-link.active {
  color: #fff !important;
  background: #085fa3;
}

/**
 * Sign up box
 */

.signup-box {
  position: relative;
  padding: 1rem;
  font-size: .9rem;
  color: #085fa3;
  background: rgba(206, 206, 206, 0.4);
  /* background: #c6c6c6; */
  border-top: 10px solid;
  transition: 0.4s all;
  /*opacity: 0.5;*/
}

.signup-box--semipresencial {
  border-color: #fed338;
}

.signup-box--presencial {
  border-color: #ed691f;
}

.signup-box--ead {
  border-color: #177fde;
}

.signup-box--disabled::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, .8);
}

.signup-box .custom-select {
  display: block;
  width: 100%;
  border-radius: 0;
}

.value {
  display: block;
}

.value--new {
  font-size: 2rem;
  line-height: 1.5;
}

@media ( min-width: 992px) {
  .value--new {
    font-size: 1.75rem;
  }
}

@media ( min-width: 1200px) {
  .value--new {
    font-size: 2rem;
  }
}

/**
 * Helpers
 */

.font-weight-xbold {
  font-weight: 800;
}

.text-blue {
  color: #08427e;
}

.text-light-blue {
  color: #91b7dd;
}

.text-dark-blue {
  color: #0a263c;
}

.text-orange {
  color: #ef6a08;
  padding-bottom: 20px;
}

/* .text-white {color #fff;} */

.bg-white {
  background: #fff;
}

.bg-blue {
  background: #08427e;
}

.bg-orange {
  background: #ef6a08;
}

.indicador {
  padding: 2px 5px;
  background: #ccc;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
}

.indicador.semi {
  background: #ffd400;
}

.indicador.presencial {
  background: #ef6c00;
}

.indicador.ead {
  background: #007ce2;
}


.obrigatorio {
  border: 1px solid #f00;
}

/* @media ( max-width: 767px) {
  .progress-container {
    display: none;
  }
} */

.progress-bar {
  background-color: #08427e;
}

.valorDesconto{
  color: red;
}

.ofertaAsteristico{
  color:red;
  font-weight: bold;
}

.progress-desc {
  font-weight: 700;
  color: #868e96;
  /* text-transform: lowercase; */
}

.progress-desc span {
  font-size: 1.5rem;
  color: var(--orange);
}

.step-1 .progress-bar {
  width: 25%;
}

.step-1 .progress-desc-1 {
  color: #08427e;
}

.step-1 .progress-desc-1 span {
  color: #ed691f;
}

.step-2 .progress-bar {
  width: 50%;
}

.step-2 .progress-desc-1, .step-2 .progress-desc-2 {
  color: #08427e;
}

.step-2 .progress-desc-1 span, .step-2 .progress-desc-2 span {
  color: #ed691f;
}


.step-3 .progress-bar {
  width: 75%;
}

.step-3 .progress-desc-1, .step-3 .progress-desc-2, .step-3 .progress-desc-3 {
  color: #08427e;
}

.step-3 .progress-desc-1 span, .step-3 .progress-desc-2 span, .step-3 .progress-desc-3 span {
  color: #ed691f;
}

.step-4 .progress-bar {
  width: 100%;
}

.step-4 .progress-desc-1, .step-4 .progress-desc-2, .step-4 .progress-desc-3, .step-4 .progress-desc-4 {
  color: #08427e;
}

.step-4 .progress-desc-1 span, .step-4 .progress-desc-2 span, .step-4 .progress-desc-3 span, .step-4 .progress-desc-4 span {
  color: #ed691f;
}


.step-enem-1 .progress-bar {
  width: 25%;
}

.step-enem-1 .progress-desc-1 {
  color: #08427e;
}

.step-enem-1 .progress-desc-1 span {
  color: #ed691f;
}

.step-enem-3 .progress-bar {
  width: 24%;
}

.step-enem-3 .progress-desc-1 {
  color: #08427e;
}

.step-enem-3 .progress-desc-1 span {
  color: #ed691f;
}

.step-enem-4 .progress-bar {
  width: 100%;
}

.step-enem-4 .progress-desc-1, .step-4 .progress-desc-2, .step-4 .progress-desc-3, .step-enem-4 .progress-desc-4 {
  color: #08427e;
}

.step-enem-4 .progress-desc-1 span, .step-4 .progress-desc-2 span, .step-4 .progress-desc-3 span, .step-enem-4 .progress-desc-4 span {
  color: #ed691f;
}


/* CSS da timeline de transferência e reingresso */
.passo-1 .progress-desc-1 span,
.passo-2 .progress-desc-1 span,
.passo-3 .progress-desc-1 span,
.passo-4 .progress-desc-1 span,
.passo-5 .progress-desc-1 span,
.passo-6 .progress-desc-1 span,
.passo-2 .progress-desc-2 span,
.passo-3 .progress-desc-2 span,
.passo-4 .progress-desc-2 span,
.passo-5 .progress-desc-2 span,
.passo-6 .progress-desc-2 span,
.passo-3 .progress-desc-3 span,
.passo-4 .progress-desc-3 span,
.passo-5 .progress-desc-3 span,
.passo-6 .progress-desc-3 span,
.passo-4 .progress-desc-4 span,
.passo-5 .progress-desc-4 span,
.passo-6 .progress-desc-4 span,
.passo-5 .progress-desc-5 span,
.passo-6 .progress-desc-5 span,
.passo-6 .progress-desc-6 span{
  color: #ed691f;
}
.passo-1 .progress-desc-1,
.passo-2 .progress-desc-1,
.passo-3 .progress-desc-1,
.passo-4 .progress-desc-1,
.passo-5 .progress-desc-1,
.passo-6 .progress-desc-1,
.passo-2 .progress-desc-2,
.passo-3 .progress-desc-2,
.passo-4 .progress-desc-2,
.passo-5 .progress-desc-2,
.passo-6 .progress-desc-2,
.passo-3 .progress-desc-3,
.passo-4 .progress-desc-3,
.passo-5 .progress-desc-3,
.passo-6 .progress-desc-3,
.passo-4 .progress-desc-4,
.passo-5 .progress-desc-4,
.passo-6 .progress-desc-4,
.passo-5 .progress-desc-5,
.passo-6 .progress-desc-5,
.passo-6 .progress-desc-6{
  color: #08427e;
}
.passo-1 .progress-bar {
  width: 16.6666667%;
}
.passo-2 .progress-bar {
  width: 32.6666667%;
}

.passo-3 .progress-bar {
  width: 48.6666667%;
}
.passo-4 .progress-bar {
  width: 64.6666667%;
}

.passo-5 .progress-bar {
  width: 80.6666667%;
}

.passo-6 .progress-bar {
  width: 100%;
}





.bt-editar-progress {
  text-transform: capitalize;
  font-size: 14px;
}

.inner-passos {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 13px;
}

.signup-box:hover {
  opacity: 1;
}

.legend-buttons {
  font-size: 12px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.legend-block {
  background: rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin-right: 4px;
  padding-right: 4px;
}

.btn-group-sm>.btn, .btn-sm {
  font-size: .870rem;
}

.card-inscrevase {
  display: none;
}

/*Inclusão de dois botões para graduação e trans. ext.*/

@media (max-width: 470px) {
  .choices {
    margin: 4% 0;
  }
}

@media (max-width: 769px) {
  .choices a {
    padding: 3% 10%;
  }
  .choice2 a {
    padding-left: 10%;
  }
}

@media (max-width:960px) {
  .choise {
    width: 90%;
    text-align: left;
  }
  .choise a {
    margin: 3% 12%;
  }
  .choise h4 {
    text-align: center;
  }
  .choice2 img {
    left: 0;
  }
  .choice2 a {
    padding-left: 3%;
  }
  .choices {
    width: 100%;
    margin: 0;
  }
  .hide-mobile {
    display: none;
  }
}

.choise a {
  margin: 3% 12%;
}

.choise h4 {
  text-align: center;
}

.choice2 img {
  left: 0;
}

.choice2 a {
  padding-left: 3%;
}

.choices {
  width: 100%;
  margin: 0;
}

.choise {
  /*width: 60%;*/
  margin: 50px auto;
  text-align: center;
}

.choices {
  position: relative;
  /*width: 50%;*/
  margin: 3% 0;
  float: left;
}

.choices img {
  border-radius: 55px;
  box-shadow: 1px 1px 37px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 12%;
  left: 1%;
}

.choice2 img {
  left: -14%;
}

.choices a {
  display: inline-block;
  background: #e4b200;
  padding: 3% 4%;
  margin: 7% 0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.choice2 a {
  padding-left: 13%;
}

.texto-observacao{
  color: #999;
  font-weight: 600;
  font-size: medium;
  text-align: center;
}

.texto-link{
  color:#085fa3;
  font-size: 17px;
  font-weight: 600;
}

.textocentralizado {
  text-align: center;
}

.saibamais {
  font-size: large;
  text-align: center;
}

@media (max-width: 770px) {
  .md-btn {
    left: 8%;
  }
}

.img-md {
  display: block;
  content: "";
  clear: both;
  max-width: 100%;
  display: block
}

.modal-title {
  position: absolute;
}

.modal-header .close {
  margin-top: -18px !important;
}

/** Alteração **/


.title-card{
  font-size: 22px;
  text-transform: uppercase;
  color: #0a263c;
  width: 50%;
}
.pre-inscricao{font-size: 14px !important;}
.clearfix::after{
   display: block;
    content: "";
    clear: both;
}
p {
    margin: 0 0 0px !important;
}
.agende{
      font-size: 15px;
}
.hTitle{
  color: rgb(8, 66, 126) !important;
  font-weight: 800 !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.modal-title{
  font-weight: 900 !important;
  font-size: 18px !important;
}
.modal-body {
    position: relative !important;
    padding: 7px !important;
}
/* label {
  font-weight: 300 !important;
} */
.select-curso{
  height: 32px !important;
}
.text_unigranrio_crusolist, .select_cursos{
  font-size: 17px;
  padding-bottom: 14px;
}
.col_list_cursos > th{
  font-size: 1rem;
  font-weight: bold;
}
.list > tr > td{
  font-size: 17px;
}
.list > tr > td > svg{
  margin-right: 15px;
}   
.btn-segunda-graduacao{
  background-color: #009688;
  -webkit-box-shadow: 0px 5px 0px 0px #00776c;
  box-shadow: 0px 5px 0px 0px #00776c;
}
.btn-segunda-graduacao:hover,
.btn-segunda-graduacao:focus{
  text-decoration: none !important;
  background-color: #02c5b3;
  /* -webkit-transition: 0.9s;
  transition: 0.9s; */
} 
.transf-externa{
  background-color: #2bab61;
  box-shadow: 0px 5px 0px 0px #19904c;
}
.transf-externa:hover,
.transf-externa:focus{
  text-decoration: none;
  background-color: #1cc764;
}
.btn-segunda-graduacao, .transf-externa{
  color: #ffffff;
  font-weight: 500;
  border: none;
  padding: 6px 30px;
  border-radius: 3px;
  font-size: 21px;
  margin-left: 35px;
  letter-spacing: 0.5px;
  /* -webkit-box-shadow: inset 0px -45px 38px -9px rgba(15,101,163,1);
  -moz-box-shadow: inset 0px -45px 38px -9px rgba(15,101,163,1);
  box-shadow: inset 0px -45px 38px -9px rgba(15,101,163,1); */
  /* -webkit-transition: 2s;
  transition: 2s; */
  letter-spacing: 0.5px;
}
.mgtop-cinquenta{
  margin-top: 50px;
}
.teste-teste::after{
  content: url ('../images/hotsite-img/segunda-graduacao.png');
}



.modal-open {
  overflow: hidden
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%)
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0)
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color:#00000094;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5
}

.modal-header {
  min-height: 16.43px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5
}

.modal-header .close {
  margin-top: -2px
}

.modal-title {
  margin: 0;
  line-height: 1.42857143
}

.modal-body {
  position: relative;
  padding: 15px
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5
}

.modal-footer .btn+.btn {
  margin-bottom: 0;
  margin-left: 5px
}

.modal-footer .btn-group .btn+.btn {
  margin-left: -1px
}

.modal-footer .btn-block+.btn-block {
  margin-left: 0
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width:768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5)
  }
  .modal-sm {
    width: 300px
  }
}

@media (min-width:992px) {
  .modal-lg {
    width: 900px
  }
}
.modal-footer:after, .modal-footer:before{
  display: table;
  content: " "
}

.modal-footer:after{
  clear: both
}
.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear
}

.fade.in {
  opacity: 1
}

@media(max-width: 390px){
  .modal-title {
    font-weight: 700 !important;
    font-size: 15px !important;
  }
  .modal-header {
    padding: 10px 15px;
  }
  .title-card{
    font-size: 20px;
  }
  .hTitle, .lead{
    font-size: 18px;
  }
  .callback .btn{
    margin: 0 auto;
    display: block;
  }
  .hero{
    padding: 30px 0;
  }
  .text_unigranrio_crusolist, .select_cursos {
    font-size: 15px;
  }
  .filters {
    margin-bottom: 1.5rem;
  }
  .main {
    padding-bottom: 2rem;
  }
  .transf-externa{
    line-height: 24px;
    margin-top: 20px;
  }
  .course-info {
    margin-bottom: 2rem;
  }
  .units-link{
    padding: 10px;
    border-radius: .25rem;
    margin-left: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
  }
  .signup-box{
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
  .btn-segunda-graduacao, .transf-externa{
    margin-left: 0px;
  }
}
.fa-angle-right{
  display: none;
}
.fa-graduation-cap, .fa-hourglass{
  color: #484848;
}
.bt-editar-progress{
  background: #085fa3;
  color: #ffffff;
  border: 1px solid #085fa3;
  padding: 3px 18px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 300;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
  font-weight: 700;
}
.bt-editar-progress:hover,
.bt-editar-progress:focus{
  color: #ffffff;
  text-decoration: none;
  background: #2e6daf;
  transition: 0.5s;
  border: 1px solid #2e6daf;
}
.bt-editar-progress > svg{
  width: 15px !important;
  margin-right: 5px;
}
#cards-pb{
  padding-bottom: 25px;
}
.textocentralizado > p:nth-child(2){
  padding-bottom: 25px;
}
.center{
  text-align: center;
}
.center > button{
  text-align: center;
  padding: 12px 30px;
  width: 300px;
  line-height: 25px;
}
.center > button{margin-top: 30px;}
.ul-info-candidato{
  list-style-type: none;
  padding-left: 0px;
  margin-top: 20px;
}
.card-info-candidato > p{font-weight: 700;padding-bottom: 3px;}
.card-info-candidato > p > span{font-weight: 300;}
.card-info-candidato{
  background: #ffffff;
}
.div-card-info{
  margin: 0 auto;
  display: block;
}
.text-inscricao{
  font-size: 20px;
  margin-top: 40px !important;
}

.width30{
  width: 30%;
}

#iframe-boleto{
  visibility: hidden;
}

@media print { 
  /* All your print styles go here */
  /* html body * { visibility: hidden; }  */

  #iframe-boleto{
    margin-right: 40px;
    padding: 20px;
    visibility: visible;
    width: 100%;
    height: 100vh;
  }
 }
 .input_file{display: block; font-size: 12px; width:100%;}
 .label_input_file{font-weight: 600;margin-bottom:11px;font-size: 14px;}
 #pd-bottom-file{padding-bottom: 20px;}
[type=file] {
  position: relative;
}
[type=file]:hover,
[type=file]:focus,
[type=file]:active{
  outline: none;
}
input[type=file]::-webkit-file-upload-button{
  border: 1px solid transparent;
  padding:3px 8px;
  border-radius: 5px;
  position: relative;
  font-size: 11px;
  background: #eeeeee;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  outline: none;  
  -webkit-transition: 0.5s; /* Safari */
    transition: 0.5s;
}
input[type=file]::-webkit-file-upload-button:hover,
input[type=file]::-webkit-file-upload-button:focus{
  background: #bdbdbd;
  -webkit-transition: 0.5s; /* Safari */
    transition: 0.5s;
}
.subtitle-doc{
  font-size: 16px;
    font-weight: 500;
    color: #ed691f;
}

.text-attention {
  margin-right:3px;
  color:#ed691f;  
  font-weight:bolder;
}

#text-attention-desistir-envio-documentos{
  top: 72px;  
  position: absolute;
  left: -25%;
  width: 40em;
}

.zero-opacity {
  opacity: 0;
}

#capture_storage_e0ab23c6-afc9-4141-9a0b-fb2fa3d8b121{
  display: none;
}